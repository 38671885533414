import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="sortable"
export default class extends Controller {
  initialize() {
    this.onUpdate = this.onUpdate.bind(this)
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      onUpdate: this.onUpdate
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  onUpdate({ item, newIndex }) {
    item.querySelector("input[name*='position']").value = newIndex + 1
  }
}
